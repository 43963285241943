import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Calendar from "react-calendar";
import "./modal.css";
import axios from "../api/axios";
import "react-calendar/dist/Calendar.css";

const ModalCalendar = ({ payDate, setPayDate, idInv, toast }) => {
  let beforeDate = payDate;

  const [show, setShow] = useState(false);

  const selectedDate = new Date();

  const handleClose = () => {
    setShow(false);
    setPayDate(beforeDate);
  };

  const handleShow = () => setShow(true);

  const onChangeDate = async (e) => {
    e.preventDefault();

    const options = {
      method: "PATCH",
      url: `facturas/date/${idInv}`,
      data: {
        date: payDate
      }
    };

    await axios.request(options).then((response) => {
        setPayDate(new Date(response.data.date));

        toast.success("Fecha modificada exitosamente.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored"
        });
      }).catch((error) => {
        console.error(error);
        toast.error("Error al modificar la fecha.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored"
        });
      });
  };
  return (
    <>
      <button className="button-48" onClick={handleShow}>
        <span class="text">
          {payDate.getDate() +
            "/" +
            (payDate.getMonth() + 1) +
            "/" +
            payDate.getFullYear()}
        </span>
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Fecha de Pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Calendar
                onChange={setPayDate}
                value={payDate}
                minDate={
                  new Date(selectedDate.setDate(selectedDate.getDate() + 1))
                }
                tileDisabled={({ activeStartDate, date, view }) =>
                  date.getDay() === 0 || date.getDay() === 6
                }></Calendar>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={onChangeDate}>
            Cambiar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalCalendar;
