import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebars from "../sidebars/sidebars";
import DataTable from "react-data-table-component";
import "./detail-representantes.css";
import ModalFormCuentaBancaria from "../representantes/modal-form-cuenta-bancaria";
import axios from "../api/axios";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";

const RepresentantesDetail = () => {
  const [pending, setPending] = useState(false);
  const [banksAccount, setBanksAccount] = useState([]);
  const { state } = useLocation();

  let name2 = "";
  let email2 = "";
  let cuil2 = "";
  let id2 = "";

  if (state) {
    const { name, email, cuil, id } = state;
    name2 = name;
    email2 = email;
    cuil2 = cuil;
    id2 = id;
  }

  const columns = [
    {
      name: "Banco",
      selector: (row) => row.bankName,
      sortable: true,
      reorder: true,
    },
    {
      name: "tipo de cuenta",
      selector: (row) => row.accountType,
      sortable: true,
      reorder: true,
    },
    {
      name: "CBU",
      selector: (row) => row.cbu,
      sortable: true,
      reorder: true,
    },
    {
      name: "moneda",
      selector: (row) => row.currency,
      sortable: true,
      reorder: true,
    },
    {
      name: "",
      selector: (row) => {
        return (
          <>
            <div className="d-flex gap-2">
              <ModalFormCuentaBancaria
                title={"edit"}
                userId={id2}
                cuil={Number(cuil2)}
                getBanks={getBanks}
                bankAccount={row}
              />
              <Button variant="danger" onClick={
                async () => {
                  try {

                    toast.info("Eliminando cuenta bancaria", {});

                    await axios.delete(
                      "representantes/" + id2 + "/bank-account/" + row._id
                    );
                    getBanks();
                    toast.success("Cuenta bancaria eliminada", {});
                  } catch (error) {
                    console.error(error);
                    toast.error("Error al eliminar la cuenta bancaria", {});
                  }
                }
              }>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-trash"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                </svg>
              </Button>
            </div>
          </>
        );
      },
    },
  ];

  const getBanks = async () => {
    try {
      const response = await axios.get(
        "representantes/" + id2 + "/bank-account/"
      );
      setBanksAccount(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getBanks();
  }, []);

  return (
    <div>
      <Sidebars></Sidebars>

      {!state ? (
        <>
          <h3>no hay datos...</h3>
        </>
      ) : (
        <>
          <div class="container-r">
            <h3>Detalle del representante</h3>
            <div class="rowform-r">
              <div class="col-r">
                <label class="label-r">Representante:</label>
                <p class="form-control-plaintext-r">{name2 ?? ""}</p>
              </div>

              <div class="col-r">
                <label class="label-r">Cuil:</label>
                <p class="form-control-plaintext-r">{cuil2 ?? ""}</p>
              </div>
            </div>
          </div>
          <div class="container-r">
            <h3>Cuenta bancarias del representante</h3>
            <div className="card text-sm-light mb-3 div-header-info">
              <ModalFormCuentaBancaria
                title={"sumar nueva cuenta bancaria"}
                userId={id2}
                cuil={Number(cuil2)}
                getBanks={getBanks}
              />
              <div className="card-body">
                <DataTable
                  columns={columns}
                  data={banksAccount}
                  highlightOnHove
                  direction="auto"
                  pagination
                  responsive
                  progressPending={pending}
                  highlightOnHover
                ></DataTable>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RepresentantesDetail;
