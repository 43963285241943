import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import axios from "../api/axios";
import close from "../../assets/close.svg";
import { toast, ToastContainer } from "react-toastify";

const ModalFormCuentaBancaria = ({
  title,
  userId,
  cuil,
  getBanks = () => {},
  bankAccount = undefined,
  icon = "",
}) => {
  const accountsTypes = [
    { label: "CC", value: "CC" },
    { label: "CA", value: "CA" },
  ];

  const currencies = [
    { label: "USD", value: "USD" },
    { label: "$", value: "$" },
  ];

  const [accountType, setAccountType] = useState(
    bankAccount?.accountType ?? "CC"
  );

  const [currency, setCurrency] = useState(bankAccount?.currentAccount ?? "$");

  const [cuitCuil, setCuitCuil] = useState(cuil ?? "");

  const [cbu, setCbu] = useState(bankAccount?.cbu ?? "");
  const [alias, setAlias] = useState(bankAccount?.alias ?? "");
  const [bank, setBank] = useState(bankAccount?.bank ?? "");

  const [show, setShow] = useState(false);

  const handleChangeCBU = (event) => {
    if (/^[0-9]{0,22}$/.test(event.target.value)) {
      setCbu(event.target.value);
    }
  };

  const createOrEditAccount = async (e) => {
    e.preventDefault();

    if (bankAccount) {
      const data = {
        accountType: accountType,
        cbu: cbu,
        alias: alias,
        cuitCuil: cuitCuil,
        currency: currency,
        bankName: bank ?? "",
      };

      try {
        const response = await axios.put(
          "representantes/" + userId + "/bank-account/" + bankAccount._id,
          data
        );
      } catch (error) {
        console.error(error);
      }
    } else {
      const data = {
        accountType: accountType,
        cbu: cbu,
        alias: alias,
        cuitCuil: cuitCuil,
        currency: currency,
        bankName: bank ?? "",
      };

      try {
        const response = await axios.post(
          "representantes/" + userId + "/bank-account/",
          data
        );
      } catch (error) {
        console.error(error);

        if (error?.response?.data?.error) {
          // revisar si error es un string o lista de errores

          if (typeof error?.response?.data?.error === "string") {
            toast.error(error?.response?.data?.error, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          } else {
            error?.response?.data?.error?.map((error) => {
              toast.error(error, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
            });
          }
        }
      }
    }

    getBanks();
    setShow(false);
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
      {title === "edit" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-pen"
                viewBox="0 0 16 16"
              >
                <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001m-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708z" />
              </svg>
            ) : (
              title
            )}
      </Button>

      <Modal dialogClassName="modal-90w" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {title === "edit" ? (
              "editar cuenta bancaria"
            ) : (
              title
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <div className="mb-3 rowform">
                <label className="col-sm-2 col-form-label">Nombre</label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control"
                    value={bank}
                    onChange={(e) => setBank(e.target.value)}
                  />
                </div>
              </div>
              <div className="mb-3 rowform selectMobile">
                <label for="staticEmail" className="col-sm-2 col-form-label">
                  Tipo de cuenta:
                </label>
                <div className="col-sm-10">
                  <Select
                    options={accountsTypes}
                    defaultValue={accountsTypes.find(
                      (option) => option.value === accountType
                    )}
                    onChange={(e) => {
                      setAccountType(e.value);
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#0d9963",
                        primary: "#0d9963b5",
                      },
                    })}
                    className="form-control-plaintext"
                  />
                </div>
              </div>
              <div className="mb-3 rowform selectMobile">
                <label for="staticEmail" className="col-sm-2 col-form-label">
                  Tipo de moneda:
                </label>
                <div className="col-sm-10">
                  <Select
                    options={currencies}
                    defaultValue={currencies.find(
                      (option) => option.value === currency
                    )}
                    onChange={(e) => {
                      setCurrency(e.value);
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#0d9963",
                        primary: "#0d9963b5",
                      },
                    })}
                    className="form-control-plaintext"
                  />
                </div>
              </div>
              <div className="mb-3 rowform">
                <label className="col-sm-2 col-form-label">CBU:</label>
                <div className="col-sm-10">
                  <input
                    size="22"
                    maxlength="22"
                    type="text"
                    className="form-control"
                    value={cbu}
                    onChange={handleChangeCBU}
                  />
                </div>
              </div>
              <div className="mb-3 rowform">
                <label className="col-sm-2 col-form-label">Alias:</label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control"
                    value={alias}
                    onChange={(e) => setAlias(e.target.value)}
                  />
                </div>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={createOrEditAccount}>
            enviar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalFormCuentaBancaria;
