import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import CurrencyInput from "../../utils/money";

import "./modal.css";

const ModalInputAmount = ({
  amount,
  changeAmount,
  setAmount,
}) => {
  const beforeAmount= amount === null ? null : amount;

  const formatterDolar = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  });

  const [activate, setActivate] = useState(false);

  const [show, setShow] = useState(false);

  const [AmountInput, setAmountInput] = useState(
    beforeAmount === null
      ? ""
      : formatterDolar.format(beforeAmount).replace("€", "")
  );

  const handleShow = () => setShow(true);

  const handleCancel = () => {
    setAmountInput(
      beforeAmount === null
        ? ""
        : formatterDolar.format(beforeAmount).replace("€", "")
    );

    setAmount(beforeAmount);
    setShow(false);
  };

  const handleAmount = (e) => {
    e.preventDefault();

    console.log(e.target.value);

    if (!e.target.value) {
      setAmountInput("");
      setAmount(null);
    } else {
      setAmountInput(e.target.value);

      let value = e.target.value.replace(/\./g, "").replace(",", ".");

      setAmount(parseFloat(value));
    }
  };

  useEffect(() => {
    if (amount === null && AmountInput === "") {
      setActivate(false);
    } else {
      setActivate(true);
    }
  }, [amount, AmountInput]);

  return (
    <>
      <button className="button-48" onClick={handleShow}>
        <span class="text">
          {amount !== null
            ? formatterDolar.format(amount).replace("€", "")
            : "0"}
        </span>
      </button>

      <Modal show={show} onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Monto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="row">
            <div>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Monto</Form.Label>
                <CurrencyInput
                  retencion={true}
                  value={AmountInput}
                  placeholder=""
                  type="text"
                  className="form-control"
                  required
                  autoFocus
                  onChange={(e) => handleAmount(e)}
                />
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancelar
          </Button>
          <Button
            variant="primary"
            onClick={changeAmount}
            disabled={!activate}
          >
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalInputAmount;
